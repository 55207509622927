export const PROGRESS_STEP_PENDING = 'pending';
export const PROGRESS_STEP_IN_PROGRESS = 'inProgress';
export const PROGRESS_STEP_COMPLETED = 'completed';
export const PROGRESS_STEP_CANCELED = 'canceled';

export const MARK_MET_MANAGER_TRANSITION_NAME = 'markMetManager';
export const MARK_MACHINE_PLACE_TRANSITION_NAME = 'markMachinePlaced';

export const SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_PENDING_PAYMENT = 'Customer.PendingPayment';
export const SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_MEET_MANAGER = 'Customer.MeetManager';
export const SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_PLACE_MACHINE = 'Customer.PlaceMachine';
export const SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_REVIEW = 'Customer.Review';

export const SELL_PURCHASE_PROGRESS_BAR_STEPS_CUSTOMER = [
  SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_PENDING_PAYMENT,
  SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_MEET_MANAGER,
  SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_PLACE_MACHINE,
  SELL_PURCHASE_PROGRESS_BAR_STEP_CUSTOMER_REVIEW,
];

export const SELL_PURCHASE_PROGRESS_BAR_STEP_SELLER_INTRO_MANAGER = 'Provider.IntroManager';
export const SELL_PURCHASE_PROGRESS_BAR_STEP_SELLER_CONFIRM_MEETING = 'Provider.ConfirmMeeting';
export const SELL_PURCHASE_PROGRESS_BAR_STEP_SELLER_REVIEW = 'Provider.Review';

export const SELL_PURCHASE_PROGRESS_BAR_STEPS_SELLER = [
  SELL_PURCHASE_PROGRESS_BAR_STEP_SELLER_INTRO_MANAGER,
  SELL_PURCHASE_PROGRESS_BAR_STEP_SELLER_CONFIRM_MEETING,
  SELL_PURCHASE_PROGRESS_BAR_STEP_SELLER_REVIEW,
];

export const FIELD_TEXT = 'TextField';
export const FIELD_TEXTAREA = 'TextareaField';
export const FIELD_LOCATION = 'LocationField';
